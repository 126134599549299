
import { Vue, Component } from "vue-property-decorator";
import {EmpreendimentoService, PessoaService, TipoAditamentoContrato} from "@/core/services/cadastros";

@Component
export default class CfgParcelaAditada extends Vue {

  filter: {
    numeroContrato:number;
    sequenciaContrato:number;
    clienteId:number;
    corretorId:number;
    empreendimentoId: [];
    dataAditamentoInicial: string;
    dataAditamentoFinal: string;

    tipoAditamentoId:[];

    dataVencimentoInicial:string,
    dataVencimentoFinal:string,
    dataBaixaInicial:string,
    dataBaixaFinal:string,

  } = {
    numeroContrato:0,
    sequenciaContrato:0,
    clienteId:0,
    corretorId:0,
    empreendimentoId: [],

    dataAditamentoInicial: '',
    dataAditamentoFinal:'',

    tipoAditamentoId:[],

    dataVencimentoInicial:'',
    dataVencimentoFinal:'',

    dataBaixaInicial:'',
    dataBaixaFinal:'',
  };  

   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Relatório de Parcelas Aditadas', disabled: true, href: '/financeiro/painel/despesas'}
  ]

    valid = true;
    empreendimentos: any = [];
    pessoas = [];
    tipos: any = [];

    get selecionarTodosEmpreendimentos(){
        return this.filter.empreendimentoId.length === this.empreendimentos.length
    }

    get SelecionarAlgunsEmpreendimentos(){
        return this.filter.empreendimentoId.length > 0 && !this.selecionarTodosEmpreendimentos
    }

    get iconEmpreendimento(){
        if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
        if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    }

    get textEmpreendimento(){
        if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
        if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
        return 'Selecionar todos'
    }

    SelecionarEmpreendimento() {
        this.$nextTick(() => {
            if (this.selecionarTodosEmpreendimentos) {
            this.filter.empreendimentoId = []
            } else {
            this.filter.empreendimentoId = this.empreendimentos.slice().map((x:any)=>x.id)
            }
        })
    }


    SelecionarTipo(){
      this.$nextTick(() => {
        if (this.SelecionarTodos) {
          this.filter.tipoAditamentoId = []
        } else {
          this.filter.tipoAditamentoId = this.tipos.slice().map((x:any)=>x.id)
        }
      })
    }
    get SelecionarTodos(){
      return this.filter.tipoAditamentoId.length === this.tipos.length
    }

    get SelecionarAlgunsTipos(){
      return this.filter.tipoAditamentoId.length > 0 && !this.SelecionarTodos
    }

    get iconEmpresa(){
      if (this.SelecionarTodos) return 'mdi-close-box'
      if (this.SelecionarAlgunsTipos) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textEmpresa(){
      if (this.SelecionarTodos) return 'Desmarcar'
      if (this.SelecionarAlgunsTipos) return 'Selecionando...'
      return 'Selecionar todos'
    } 
 

    mounted() { 
        new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        res => {
            this.pessoas = res.data.items;
         });

        new TipoAditamentoContrato().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        res => {
            this.tipos = res.data.items;
        });

        new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        (res) => {
            this.empreendimentos = res.data.items;
        }); 
    } 

    Visualizar() {
        if((!this.filter.dataVencimentoInicial || !this.filter.dataVencimentoFinal) && (!this.filter.dataBaixaInicial || !this.filter.dataBaixaFinal) && (!this.filter.dataAditamentoInicial || !this.filter.dataAditamentoFinal)){
            this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos uma das datas.", "warning");
            return true;
        }      
        let routeLink = this.$router.resolve({ 
        name: "RelParcelaAditada",
            query: {
            
            numeroContrato: this.filter.numeroContrato ? this.filter.numeroContrato.toString() : "",
            sequenciaContrato: this.filter.sequenciaContrato ? this.filter.sequenciaContrato.toString() : "",
            clienteId: this.filter.clienteId ? this.filter.clienteId.toString() : "",
            corretorId: this.filter.corretorId ? this.filter.corretorId.toString() : "",

            empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : "",
            dataAditamentoInicial: this.filter.dataAditamentoInicial ? this.filter.dataAditamentoInicial.toString() : "",
            dataAditamentoFinal: this.filter.dataAditamentoFinal ? this.filter.dataAditamentoFinal.toString() : "",
            tipoAditamentoId: this.filter.tipoAditamentoId ? this.filter.tipoAditamentoId.toString() : "",
            dataVencimentoInicial: this.filter.dataVencimentoInicial ? this.filter.dataVencimentoInicial.toString() : "",
            dataVencimentoFinal: this.filter.dataVencimentoFinal ? this.filter.dataVencimentoFinal.toString() : "",
            dataBaixaInicial: this.filter.dataBaixaInicial ? this.filter.dataBaixaInicial.toString() : "",
            dataBaixaFinal: this.filter.dataBaixaFinal ? this.filter.dataBaixaFinal.toString() : "",
            },
        });
        window.open(routeLink.href, "_blank");
    }
}
